import {
  Pagination as MuiPagination,
  PaginationItem,
  Stack,
} from '@mui/material'
import { SelectRowsPerPage } from 'components/RowsPerPageSelect'
import { useMemo } from 'react'

export type PaginationType = {
  skip: number
  limit: number
}

type Props = {
  page: number
  total: number
  rowsPerPage?: number
  onChange: (n: number) => void
  onRowsPerPageChange: (n: number) => void
  rowsOptions: readonly number[]
}

export const Pagination = ({
  page,
  rowsPerPage = 10,
  total,
  onChange,
  onRowsPerPageChange,
  rowsOptions,
}: Props) => {
  const count = useMemo(
    () => Math.ceil(total / rowsPerPage),
    [total, rowsPerPage],
  )

  return (
    <Stack direction="row" spacing={2}>
      <SelectRowsPerPage
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        rowsOptions={rowsOptions}
      />
      <MuiPagination
        count={count}
        shape="rounded"
        page={page}
        variant="text"
        siblingCount={1}
        boundaryCount={1}
        color="secondary"
        onChange={(_e, page) => onChange(page)}
        renderItem={item => (
          <PaginationItem
            sx={{
              '&.MuiButtonBase-root.Mui-selected': {
                color: '#fff',
              },
              color: 'text.primary',
              '&:hover': {
                color: '#fff',
                backgroundColor: '#c7d0ff',
              },
            }}
            {...item}
          />
        )}
      />
    </Stack>
  )
}
